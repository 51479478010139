define("v-avatar/components/v-avatar", ["exports", "v-avatar/templates/components/v-avatar"], function (_exports, _vAvatar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    $
  } = window;
  const VIKUS_SYSTEM = 'h4W1__oLJkuAS1gtLJpk_Q';

  var _default = Ember.Component.extend({
    layout: _vAvatar.default,
    tagName: 'span',
    classNames: ['avatar'],
    user: Ember.inject.service(),
    titlePlacement: Ember.computed('placement', function () {
      return this.placement || 'top';
    }),
    url: Ember.computed('userId', 'version', 'isVikusLegacy', function () {
      const allowedHosts = ['hcshiring.com', 'vikus.net', 'hcsstage.com'];
      const hostname = new URL(window.location.href).hostname;
      let cdn;

      if (allowedHosts.includes(hostname) || allowedHosts.some(host => hostname === host || hostname.endsWith(".".concat(host)))) {
        cdn = hostname === 'hcsstage.com' || hostname.endsWith('.hcsstage.com') ? 'https://cdn-stage.healthcaresource.com' : 'https://cdn.healthcaresource.com';
      } else {
        cdn = 'https://cdn-dev.healthcaresource.com';
      }

      let cId = this.get('clientId') || this.get('user.clientId');
      let uId = this.get('userId');
      let version = this.get('version');
      let isVikusLegacy = this.get('isVikusLegacy'); // Use reserved avatar

      if (uId === VIKUS_SYSTEM) {
        return "https://vikus.s3.amazonaws.com/assets/images/logo/symplr-s-sq.png";
      } else if (Ember.isNone(uId)) {
        return "".concat(cdn, "/reserved/avatar/unknownUser.png");
      } else if (version > 0) {
        return "".concat(cdn, "/").concat(cId, "/avatar/").concat(uId, ".jpg?v=").concat(version);
      }
    }),

    didInsertElement() {
      this._super(...arguments); // Floats require use of a different container


      $(this.element.querySelector('img, svg')).tooltip({
        container: this.get('parent')
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      $(this.element.querySelector('img, svg')).tooltip('destroy');
    }

  });

  _exports.default = _default;
});